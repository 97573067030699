// import bramble from "../images/bramble.jpg"
// import cubaLibre from "../images/cuba-libre.jpg"
// import ginFizz from "../images/gin-fizz.jpg"
// import mintJulep from "../images/mint-julep.jpg"
// import mojito from "../images/mojito.jpg"
// import negroni from "../images/negroni.jpg"
// import oldFashioned from "../images/old-fashioned.jpg"
// import pinaColada from "../images/pina-colada.jpg"
// import whiskeySour from "../images/whiskey-sour.jpg"

export const DrinkData = [
  {
    id: 1,
    funding: 119,
    founded: 2015,
    permalink: "clearbanc",
    api_path: "organizations/clearbanc",
    web_path: "organization/clearbanc",
    api_url: "https://api.crunchbase.com/v3.1/organizations/clearbanc",
    name: "Clearbanc",
    stock_exchange: null,
    stock_symbol: null,
    primary_role: "investor",
    short_description: "Clearbanc provides growth capital for web-enabled businesses through its 20-minute term sheet.",
    profile_image_url: "http://public.crunchbase.com/t_api_images/kvr82cgdrlxjt0xiersh",
    domain: "clearbanc.com/",
    homepage_url: "https://clearbanc.com/",
    facebook_url: "https://www.facebook.com/clearbanc/",
    twitter_url: "https://twitter.com/clearbanc",
    linkedin_url: "https://www.linkedin.com/company/clearbanc/",
    city_name: "Toronto",
    region_name: "Ontario",
    country_code: "Canada",
    created_at: 1521807819,
    updated_at: 1564676634
  }, {
    id: 2,
    funding: 75,
    founded: 2015,
    permalink: "drop-loyalty",
    api_path: "organizations/drop-loyalty",
    web_path: "organization/drop-loyalty",
    api_url: "https://api.crunchbase.com/v3.1/organizations/drop-loyalty",
    name: "Drop",
    stock_exchange: null,
    stock_symbol: null,
    primary_role: "company",
    short_description: "Intelligent mobile rewards platform",
    profile_image_url: "http://public.crunchbase.com/t_api_images/iz5ejfw5ctvijc9vsn1e",
    domain: "joindrop.com",
    homepage_url: "https://www.joindrop.com",
    facebook_url: "https://www.facebook.com/joindrop",
    twitter_url: "https://twitter.com/joindrop",
    linkedin_url: "https://www.linkedin.com/company/joindrop",
    city_name: "Toronto",
    region_name: "Ontario",
    country_code: "Canada",
    created_at: 1452635212,
    updated_at: 1598537655
  }, {
    id: 3,
    funding: 243,
    founded: 2015,
    permalink: "applyboard",
    api_path: "organizations/applyboard",
    web_path: "organization/applyboard",
    api_url: "https://api.crunchbase.com/v3.1/organizations/applyboard",
    name: "ApplyBoard",
    stock_exchange: null,
    stock_symbol: null,
    primary_role: "company",
    short_description: "ApplyBoard is the world's largest online platform for study abroad.",
    profile_image_url: "http://public.crunchbase.com/t_api_images/ocz5jotrgrbvd5h4n10w",
    domain: "applyboard.com",
    homepage_url: "http://www.applyboard.com",
    facebook_url: "https://www.facebook.com/applyboard",
    twitter_url: "https://twitter.com/applyboard",
    linkedin_url: "https://www.linkedin.com/company/applyboard",
    city_name: "Waterloo",
    region_name: "Ontario",
    country_code: "Canada",
    created_at: 1433539701,
    updated_at: 1600188002
  }, {
    id: 4,
    funding: 'Unknown',
    founded: 2016,
    permalink: "bookjane",
    api_path: "organizations/bookjane",
    web_path: "organization/bookjane",
    api_url: "https://api.crunchbase.com/v3.1/organizations/bookjane",
    name: "BookJane",
    stock_exchange: null,
    stock_symbol: null,
    primary_role: "company",
    short_description: "BookJane combines new technology with thoughtful design to solve the problems present in the care industry today.",
    profile_image_url: "http://public.crunchbase.com/t_api_images/v1472006802/bvpegjpqwo3ff90czsix.jpg",
    domain: "bookjane.com",
    homepage_url: "https://bookjane.com",
    facebook_url: "https://www.facebook.com/bookjane",
    twitter_url: "https://www.twitter.com/bookjane1",
    linkedin_url: null,
    city_name: "Toronto",
    region_name: "Ontario",
    country_code: "Canada",
    created_at: 1472006958,
    updated_at: 1561411659
  }, {
    id: 5,
    funding: 53,
    founded: 2016,
    permalink: "symend",
    api_path: "organizations/symend",
    web_path: "organization/symend",
    api_url: "https://api.crunchbase.com/v3.1/organizations/symend",
    name: "Symend",
    stock_exchange: null,
    stock_symbol: null,
    primary_role: "company",
    short_description: "Symend is a behavioral analytics platform that provides customer engagement products to identify customers having trouble with their bills.",
    profile_image_url: "http://public.crunchbase.com/t_api_images/kxavc7g85d702et6kvrj",
    domain: "symend.com/",
    homepage_url: "http://www.symend.com/",
    facebook_url: "https://www.facebook.com/symendinc/?ref=page_internal",
    twitter_url: "https://www.twitter.com/SymendInc",
    linkedin_url: "https://www.linkedin.com/company/symend-inc/",
    city_name: "Calgary",
    region_name: "Alberta",
    country_code: "Canada",
    created_at: 1481256569,
    updated_at: 1588943707
  }, {
    id: 6,
    funding: 89,
    founded: 2016,
    permalink: "dialogue",
    api_path: "organizations/dialogue",
    web_path: "organization/dialogue",
    api_url: "https://api.crunchbase.com/v3.1/organizations/dialogue",
    name: "Dialogue",
    stock_exchange: null,
    stock_symbol: null,
    primary_role: "company",
    short_description: "Dialogue is a healthcare company that provides virtual care treatment and telemedicine for chronic diseases.",
    profile_image_url: "http://public.crunchbase.com/t_api_images/hd9gb7dgfaltlthyk8cp",
    domain: "dialogue.co/",
    homepage_url: "https://www.dialogue.co/",
    facebook_url: "https://www.facebook.com/godialogue",
    twitter_url: "https://www.twitter.com/godialogue",
    linkedin_url: "https://www.linkedin.com/company/dialogue-md/",
    city_name: "Montréal",
    region_name: "Quebec",
    country_code: "Canada",
    created_at: 1470477719,
    updated_at: 1596119121
  }, {
    id: 7,
    funding: 10,
    founded: 2013,
    permalink: "bluedot",
    api_path: "organizations/bluedot",
    web_path: "organization/bluedot",
    api_url: "https://api.crunchbase.com/v3.1/organizations/bluedot",
    name: "BlueDot",
    stock_exchange: null,
    stock_symbol: null,
    primary_role: "company",
    short_description: "BlueDot protects people around the world from infectious diseases with human and artificial intelligence.",
    profile_image_url: "http://public.crunchbase.com/t_api_images/bmqijxgond5qx1byk72c",
    domain: "bluedot.global/",
    homepage_url: "https://bluedot.global/",
    facebook_url: null,
    twitter_url: "http://twitter.com/hellobluedot",
    linkedin_url: "https://www.linkedin.com/company/bluedotinc/",
    city_name: "Toronto",
    region_name: "Ontario",
    country_code: "Canada",
    created_at: 1417603018,
    updated_at: 1581048062
  }, {
    id: 8,
    funding: 76,
    founded: 2014,
    permalink: "league",
    api_path: "organizations/league",
    web_path: "organization/league",
    api_url: "https://api.crunchbase.com/v3.1/organizations/league",
    name: "League",
    stock_exchange: null,
    stock_symbol: null,
    primary_role: "company",
    short_description: "League is a digital health platform that connects people to a comprehensive network of health services and benefits.",
    profile_image_url: "http://public.crunchbase.com/t_api_images/q5v6fnzshsjnqpwjvpe2",
    domain: "league.com",
    homepage_url: "https://league.com",
    facebook_url: "https://www.facebook.com/leagueinc",
    twitter_url: "http://twitter.com/joinleague",
    linkedin_url: "https://www.linkedin.com/company/league-inc-?trk=company_logo",
    city_name: "Toronto",
    region_name: "Ontario",
    country_code: "Canada",
    created_at: 1415852393,
    updated_at: 1572408230
  }, {
    id: 9,
    funding: 58,
    founded: 2014,
    permalink: "koho-financial",
    api_path: "organizations/koho-financial",
    web_path: "organization/koho-financial",
    api_url: "https://api.crunchbase.com/v3.1/organizations/koho-financial",
    name: "KOHO Financial",
    stock_exchange: null,
    stock_symbol: null,
    primary_role: "company",
    short_description: "KOHO Financial provides an application based banking platform intended to simplify financial products available to everyone.",
    profile_image_url: "http://public.crunchbase.com/t_api_images/kzyvl7kdci5cdzmyg0mi",
    domain: "koho.ca/",
    homepage_url: "http://www.koho.ca/",
    facebook_url: "https://www.facebook.com/KOHO/",
    twitter_url: "https://twitter.com/GetKoho",
    linkedin_url: "https://www.linkedin.com/company/koho/",
    city_name: "Toronto",
    region_name: "Ontario",
    country_code: "Canada",
    created_at: 1421823228,
    updated_at: 1575468678
  }, {
    id: 10,
    funding: 74,
    founded: 2015,
    permalink: "getmaple",
    api_path: "organizations/getmaple",
    web_path: "organization/getmaple",
    api_url: "https://api.crunchbase.com/v3.1/organizations/getmaple",
    name: "Maple",
    stock_exchange: null,
    stock_symbol: null,
    primary_role: "company",
    short_description: "Quality healthcare that fits your life",
    profile_image_url: "http://public.crunchbase.com/t_api_images/laa35qxixiqr270vso5l",
    domain: "getmaple.ca",
    homepage_url: "https://www.getmaple.ca",
    facebook_url: "https://www.facebook.com/getmaple",
    twitter_url: "https://www.twitter.com/getmaple",
    linkedin_url: "https://www.linkedin.com/company/mapleonlinehealthcare/",
    city_name: "Toronto",
    region_name: "Ontario",
    country_code: "Canada",
    created_at: 1492196424,
    updated_at: 1597720243
  }
];

export let activeDrinkIndex = 1;

// {
//   id: 2,
//   name: 'Pisco Sour',
//   description: 'A pisco sour is an alcoholic cocktail of Peruvian origin that is typical of the cuisines from Chile and Peru.',
//   details: "The drink's name comes from pisco, which is its base liquor, and the cocktail term sour, in reference to sour citrus juice and sweetener components. The Peruvian pisco sour uses Peruvian pisco as the base liquor and adds freshly squeezed lime juice, simple syrup, ice, egg white, and Angostura bitters. The Chilean version is similar, but uses Chilean pisco and pica lime, and excludes the bitters and egg white. Other variants of the cocktail include those created with fruits like pineapple or plants such as coca leaves.",
//   alcohols: ['Pisco'],
//   ingredients: [{name: 'Pisco', amount: '4.5cl'}, {name: 'Lime Juice', amount: '3cl'}, {name: 'Simple Syrup', amount: '2cl'}, {name: 'Egg White', amount: '1'}],
//   garnish: 'Angostura Bitters',
//   glass: 'Old Fashioned Glass',
//   preparation: 'Vigorously shake contents in a cocktail shaker with ice cubes, then strain into a glass and garnish with bitters.',
//   image: 'https://tul.imgix.net/content/article/Pisco-Sour.jpg?auto=format,compress&w=740&h=486&fit=crop',
// },
// {
//   id: 6,
//   name: 'Tom Collins',
//   description: 'The Tom Collins is a Collins cocktail made from gin, lemon juice, sugar, and carbonated water.',
//   details: "A John Collins is a cocktail which was attested to in 1869, but may be older. It is believed to have originated with a headwaiter of that name who worked at Limmer's Old House in Conduit Street in Mayfair, which was a popular London hotel and coffee house around 1790–1817.",
//   alcohols: ['Gin'],
//   ingredients: [{name: 'Gin', amount: '4.5cl'}, {name: 'Lemon Juice', amount: '3cl'}, {name: 'Sugar Syrup', amount: '1.5cl'}, {name: 'Carbonated Water', amount: '6cl'}],
//   garnish: ['Lemon Slice and Maraschino Cherry'],
//   glass: 'Collins Glass',
//   preparation: 'Pour all ingredients directly into highball glass filled with ice. Stir gently. Garnish. Add a dash of Angostura bitters.',
//    image: 'https://i.pinimg.com/originals/bd/f2/fd/bdf2fd92a54e27878511cd54036ef662.jpg',
// },
